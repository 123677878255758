import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ChatRoles } from "../../classes/ChatMessage";
import Markdown from "react-markdown";
import RecordButton from "../../components/general/RecordButton";
import CustomButton from "../../components/general/CustomButton";
import { ArrowUpward, Close, Edit, Help, Settings } from "@mui/icons-material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { fontSizes } from "../../Constants/Fonts";
import IntakeSettings from "./IntakeSettings";
import IntakeHelp from "./IntakeHelp";
import { useTheme } from "@emotion/react";
import { makeAuthenticatedRequest } from "../../util/Firebase";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import IntakeWarning from "./IntakeWarning";

export default function IntakeChat({bottomScroll=true, submitAudio, handleSubmit, handleGenerate, logs, isLoading,
  updateSettings=()=>{}, promptAi="inflection_3_pi", interviewerAi='inflection_3_pi'
}){

    const messagesEndRef = useRef(null);
    const [prompt, setPrompt] = useState("")
    const [openSettings, setOpenSettings] = useState(false)
    const [openHelp, setOpenHelp] = useState(false)
    const [audioUrl, setAudioUrl] = useState(null)
    const [loadingAudio, setLoadingAudio] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [audioMuted, setAudioMuted] = useState(false)
    const [lastAudio, setLastAudio] = useState(null)
    const [textToTranscribe, setTextToTranscribe] = useState(null)
    const [openWarning, setOpenWaring] = useState(false)

    const theme = useTheme()

    const audioRef = useRef(null)
    const autoPlay = useRef(false)

    const audioAbort = new AbortController()

    useEffect(() => {
      if(audioUrl && audioRef.current){
        audioRef.current.load()
        console.log('test', autoPlay.current)
        if(autoPlay.current){
          audioRef.current.play().then(() => setPlaying(true)).catch(err => {
            //error for example browser blocks autoplay
            console.error('could not auto play', err)
            //reload audio state
            audioRef.current.load()
            setPlaying(false)
          })
          
          
          autoPlay.current = false
        }
        
      }
  
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
          setPlaying(false)
        }
      }
        
    }, [audioUrl])
    

    const getSpeech = (text, controller=audioAbort) => {
      setLoadingAudio(true)
        makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/whisper/text_to_speech`,{
          method:"POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({input: text }),
          signal: controller ? controller.signal : undefined
        }).then(async (resp) => {
          if(resp.ok){
            console.log('setting audio url')
            //revoke previous url
            if(audioUrl){
              URL.revokeObjectURL(audioUrl);
            }
            
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
            setLastAudio(text)
          }else{
              console.error('error', await resp.text())
          }
          setLoadingAudio(false)
        }).catch(err => {console.error(err); setLoadingAudio(false)})
  
    }
    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit(prompt);
          setPrompt("")
        }
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if(bottomScroll)
            scrollToBottom();

      const lastMessage = logs[logs.length - 1]
      if(lastMessage.role === ChatRoles.ASSISTANT){
        setTextToTranscribe(lastMessage.message)
        if(!audioMuted ){
          const speechController = new AbortController()
          getSpeech(lastMessage.message, speechController)

          return () => {speechController.abort()}
        }else{
          //if failed to render because muted, make sure to turn back off autoplay
          autoPlay.current = false
        }
      }
    }, [logs]);

    const isDataSufficient = (requiredRoles=[ChatRoles.ASSISTANT, ChatRoles.USER], minWordCount=100) => {
      //used to sanity check if user can click the done button

      //for our purposes we want at least 1 back and forth as well as a certain amount of characters
      
      //make sure there's at least one exchange

      const foundRoles = new Map();

      //initialize the foundRoles map with the required roles and a count of 0
      for (const role of requiredRoles) {
        foundRoles.set(role, 0);
      }

      for (const item of logs) {
        if (requiredRoles.includes(item.role)) {
          foundRoles.set(item.role, foundRoles.get(item.role) + 1);
        }
      }

      //check if at least two of each required role was found
      for (const [_, count] of foundRoles.entries()) {
        if (count < 2) {
          return false;
        }
      }

      //make sure there's at least 100 words exchanges
      let wordCount = 0;
      for (let i = 0; i < logs.length; i++) {
        wordCount += logs[i].message.trim().split(' ').length;
      }

      return wordCount >= minWordCount
    }

    const handleSubmitAudio = (transcript) => {
      submitAudio(transcript)
      autoPlay.current = true
    }

    const handleDone = () => {
        if(!isDataSufficient()){
          console.log("insufficient data")
          setOpenWaring(true)
          return
        }

        handleGenerate()
    }

    

    return (
        <>
        <audio src={audioUrl} type="audio/wav" muted={audioMuted} ref={audioRef} 
        onEnded={(e) => {e.preventDefault(); setPlaying(false);  audioRef.current.currentTime = 0;}}/>
        
        <IntakeHelp 
        open={openHelp}
        onClose={() => setOpenHelp(false)}/>
        
        <IntakeWarning
        open={openWarning}
        onClose={()=>{setOpenWaring(false)}}
        />
        
        <IntakeSettings onError={(e) => console.error(e)} onUpdate={updateSettings} promptAi={promptAi} 
        interviewerAi={interviewerAi} onClose={() => setOpenSettings(false)} openSettings={openSettings}/>

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflowY: 'auto',
            maxWidth:'100%'
        }}>
        {/* Chat messages area - scrollable */}
        <Box sx={{ 
          flexGrow:1,
          overflowY: 'auto',
          height:'10rem',
          padding: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
          {logs && logs.map((l, index) => (
            <Box key={index} sx={{
              display: 'flex',
              justifyContent: l.role === ChatRoles.USER ? 'flex-end' : 'flex-start',
            }}>
              <Box sx={{ maxWidth: '80%' }}>
                <Typography sx={{fontSize: fontSizes.default+1}}>
                  <Markdown>{l.message}</Markdown>
                </Typography>
              </Box>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {/* Input area - fixed at bottom */}
        <Box sx={{ 
          flexShrink: 0,
          paddingX: 2,
          flexDirection:'row'
        }}>
          <IconButton onClick={() => setOpenSettings(true)}>
            <Settings/>
          </IconButton>
          {/* <IconButton onClick={() => setOpenHelp(true)}>
            <Help/>
          </IconButton> */}
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Box sx={{display:'flex', flexDirection:'column', flexGrow:1}}>
            <TextField 
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            multiline
            rows={{xs:1, md:2}}
            onKeyDown={handleKeyPress}
            InputProps={{
              startAdornment: (
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <RecordButton onTranscribe={handleSubmitAudio}/>
                </Box>
              ),
              endAdornment: isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton onClick={(e) => {e.preventDefault(); handleSubmit(prompt); setPrompt("")}} color="primary" size="large">
                  <ArrowUpward />
                </IconButton>
              ),
            }}
          />
            <CustomButton 
              style={{marginTop:'10px'}}
              onClick={(e) => {e.preventDefault(); handleDone()}} 
              // disabled={!isDataSufficient()}
              to={1} from={1}
              >
                Done
              </CustomButton>
            </Box>
          <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', margin:'2px'}}>
            {audioRef.current &&
              <Box>
              {
                !audioUrl || loadingAudio ?
                <CircularProgress size={24}/>
                :
                playing ? 
                <IconButton onClick={(e) => {e.preventDefault(); setPlaying(false); audioRef.current.pause()}} sx={{color: theme.palette.primary.main}}>
                  <StopIcon/>
                </IconButton>
                :
                <IconButton onClick={(e) => {
                  e.preventDefault(); 
                  if(textToTranscribe != lastAudio){
                    autoPlay.current = true
                    getSpeech(textToTranscribe)
                    return
                  }
                  setPlaying(true); 
                  audioRef.current.play().then(() => setPlaying(true)).catch(err => console.error('could not auto play', audioUrl, err))
                }} sx={{color: theme.palette.primary.main}}>
                  <PlayArrowIcon/>
                </IconButton>
              }
              </Box>
            }
            <IconButton onClick={() => setAudioMuted(!audioMuted)} sx={{color:audioMuted ? theme.palette.error.main : theme.palette.primary.main}}>
            {audioMuted ? 
              <Tooltip title="unmute audio" placement='top'>
              <VolumeOffIcon color={theme.palette.error.main}/> 
              </Tooltip>
              : loadingAudio ?
              <CircularProgress size={24}/>
              :
              <Tooltip title="mute audio" placement='top'>
                <VolumeUpIcon color={theme.palette.primary.main}/>
              </Tooltip>
            }
            </IconButton>
          </Box>
          </Box>
        </Box>
      </Box>
      </>
    )
}