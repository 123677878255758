import { useQuery } from "@tanstack/react-query";
import { getExperts } from "./actions";
import { Button, IconButton, Typography, Box, Modal, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import { doubleGradient } from "../../Constants/Colors";
import AppBar from "../general/AppBar";
import ExpertSettings from "./ExpertSettings";
import BackgroundTemplate from "../general/BackgroundTemplate";

function Settings() {
  const experts = useQuery({ queryKey: ["experts"], queryFn: getExperts });
  const [selectedExpert, setSelectedExpert] = useState("");
  useEffect(() => {
    if(experts.data && experts.data.length > 0 && !selectedExpert) {
      setSelectedExpert(experts.data[experts.data.length-1].id);
    }
  }, [experts.data]);


  return (
    <BackgroundTemplate>
    <Box display="flex" sx={{flexDirection: 'column', overflow: 'hidden', flexGrow:1}}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Select
          value={selectedExpert}
          onChange={(e) => setSelectedExpert(e.target.value)}
          sx={{ width: "50%", mt: 2 }}
        >
          {experts.data?.map((expert) => (
            <MenuItem key={expert.id} value={expert.id}>{expert.name}</MenuItem>
          ))}
        </Select>
        <ExpertSettings expert={experts.data?.find((expert) => expert.id === selectedExpert)}/>
      </Box>
      
      {/* <ExpertModal open={createExpertsOpen} setOpen={setCreateExpertsOpen} />
      <Typography variant="h4" gutterBottom>
        Experts
      </Typography>
      <Button variant="contained" onClick={() => setCreateExpertsOpen(true)}>
        Create Expert
      </Button>
      {experts.data?.map((expert) => (
        <Box key={expert.id} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {expert.name}
          </Typography>
          <IconButton onClick={() => setCreateExpertsOpen(expert)}>
            <EditIcon />
          </IconButton>
        </Box>
      ))} */}
    </Box>
    </BackgroundTemplate>
  );
}

export default Settings;