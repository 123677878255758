import { Button, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { themeOptions } from "../../Constants/Colors";
import { fontSizes } from "../../Constants/Fonts";

const defaultStyle ={
    background:'transparent',
    border:'none',
    width:'100%',
}

const defaultButton = {
    fullWidth: true,
    variant: 'contained',
    fontSize: fontSizes.default,
    sx:{
        textTransform:"none",
        borderRadius:20,
        height:'100%',
        color: themeOptions.palette.text.contrast,
        '&:hover':{
            color:themeOptions.palette.secondary.main
        }
    }
}

export default function CustomButton({
    buttonProps=defaultButton, 
    style=defaultStyle, 
    children, 
    onClick, 
    from=1, 
    to=1.05, 
    tap=0.95, 
    rotate=false,
    disabled=false,
    tooltip=""
}){

    const getTwist=()=>{
        let flip = Math.random()
        let direction = 1
        if(flip > 0.5)
            direction = -1

        return [0,15*direction,10*-direction,5*direction,2*-direction,0]
    }

    return(
    <motion.button
        initial={{
            rotate:0,
            scale:from
        }}
        whileTap={{
            scale:tap,
            rotate: rotate ? getTwist() : 0
        }}
        whileHover={{scale:to}}
        style={{...defaultStyle, ...style}}
        onClick={onClick}
        disabled={disabled}
    >   
    <Tooltip title={tooltip} placement="top">
        <span>
        <Button {...{...defaultButton, ...buttonProps}} disabled={disabled}>
            {children}
        </Button>
        </span>
    </Tooltip>
    </motion.button>
    )
}
