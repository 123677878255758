import { Save } from "@mui/icons-material"
import { Autocomplete, IconButton, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { caseStudyPrompt } from "../../Constants/Prompts"
import { getUserEmail } from "../../util/Firebase"

export default function IntakeSettings({onError, onUpdate=()=>{}, promptAi="inflection"}){
    const [currentAi, setCurrentAi] = useState(promptAi)
    const [aiOptions, setAiOptions] = useState([])
    const [aiPrompt, setAiPrompt] = useState(caseStudyPrompt)
    

    useEffect(()=>{
        onUpdate({aiPrompt, currentAi})
    },[aiPrompt])

    const getPrompt = async () => {
        try{
          const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
          {
            headers:{
              "Authorization": getUserEmail()
            }
          })
  
          if(!resp.ok){
            //if response fails, do nothing
            //we will go to the default query
            return
          }
  
          const json = await resp.json()
          setAiPrompt(json.summarize_prompt)
        }
        catch(e){
          console.error(e)
          onError(e.message)
        }
      }

    const getAiOptions = async () => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/studies/aiOptions`)
        const json = await resp.json()
        if(!resp.ok)
          throw new Error(json.message)

        setAiOptions(json.options)
      }catch(e){
        onError(e.message)
      }
      
    }
    
    useEffect(()=>{
        getPrompt()
        getAiOptions()
    },[])

    const updateAiPrompt = async (newPrompt=aiPrompt) => {
        try{
          const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
          {
            method: "PUT",
            headers:{
              "Authorization": getUserEmail(),
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              summarize_prompt: newPrompt,
              intake_structured_model: currentAi
            })
          })
          
          const json = await resp.json()
          if(!resp.ok){
            //if response fails, do nothing
            //we will go to the default query
            throw new Error(json.error)
          }
  
          
          setAiPrompt(json.summarize_prompt)
          onUpdate({aiPrompt:json.summarize_prompt, currentAi})
        }
        catch(e){
          console.error(e)
          onError(e.message)
        }
    }
  

    return (
        <>
        <Autocomplete
          options={aiOptions}
          onChange={(e, value) => setCurrentAi(value)}
          value={currentAi}
          fullWidth
          renderInput={(params) => <TextField {...params}  label="Current Summarizer LLM"/>}
        />
        <TextField
            value={aiPrompt}
            onChange={(e) => setAiPrompt(e.target.value)}
            fullWidth
            multiline
            />
        <>
        <IconButton onClick={(e) => {e.preventDefault(); updateAiPrompt()}}>
            <Save/>
        </IconButton> 
        </>
        </>
    )
}