export const ChatRoles = {
    ASSISTANT: "Assistant",
    USER: 'User'
}

export class ChatMessage{

    constructor(message, role=ChatRoles.ASSISTANT){
        this.message = message
        this.role = role
    }
}
