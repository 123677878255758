import React, { createContext, useContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeOptions } from './Constants/Colors';
import Landing from './pages/Landing';
import SignIn from './pages/SignIn';
import ProtectedRoute from './util/ProtectedRoute';
import Search from './pages/Search';
import ChatBot from './components/chatbot/ChatBot';
import FindExperts from './pages/FindExperts';
import NotFound from './pages/NotFound';
import About from './pages/About';
import ScrollToTop from './components/general/ScrollToTop';
import Settings from './components/chatbot/Settings';
import CreateExpert from './pages/CreateExpert/CreateExpert.js';
import IntakePage from './pages/Intake/IntakePage.js';
import { useAdminStatus } from './util/Firebase.js';
// Create the context
const RouteContext = createContext();

// Custom hook to use the routes
export const useNavRoutes = () => {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error('useNavRoutes must be used within a RouteProvider');
  }
  return context;
};

// Define base routes and nav routes
const globalRoutes = [
  {
    name: "Home",
    path: "/",
    element: <ProtectedRoute element={<Landing/>}/>,
  },
  {
    path: "/signin",
    element: <SignIn/>
  },
  {
    path: "/*",
    element: <NotFound/>
  }
];

const adminRoutes = [
    {
        path: "/experts",
        element: <ProtectedRoute element={<FindExperts/>}/>
    },
    {
        path: "/experts/chat",
        element: <ProtectedRoute element={<ChatBot/>}/>
    },
]

const userNavRoutes = [
    {
        order: 1,
        name: "Intake",
        path: "/intake",
        element: <ProtectedRoute element={<IntakePage/>}/>
    },
    
    {
        order:5,
        name: "Company",
        path: "/company",
        element: <ProtectedRoute element={<About/>}/>
    },
    
];

const adminNavRoutes = [
    {
        order:2,
        name: "Create",
        path: "/experts/create",
        element: <ProtectedRoute element={<CreateExpert/>}/>
    },
    {
        order: 3,
        name: "Search",
        path: "/search",
        element: <ProtectedRoute element={<Search/>}/>
    },
    {
        name: "Settings",
        path: "/settings",
        element: <ProtectedRoute element={<Settings/>}/>
    }
];

const AppRouter = () => {
    const { isAdmin, loading } = useAdminStatus();
    const theme = createTheme(themeOptions);
    const queryClient = new QueryClient();
    const baseRoutes = [...globalRoutes, ...(isAdmin ? adminRoutes : [])]
    // Combine routes based on admin status
    const navRoutes = [...userNavRoutes, ...(isAdmin ? adminNavRoutes : [])]
        .sort((a, b) => {
            // If both routes have order, sort normally
            if (a.order && b.order) {
                return a.order - b.order;
            }
            // If only a has order, it should come first
            if (a.order) return -1;
            // If only b has order, it should come first
            if (b.order) return 1;
            // If neither has order, maintain original position
            return 0;
        });

    const router = createBrowserRouter([
    ...navRoutes,
    ...baseRoutes
    ]);

    const contextValue = {
        navRoutes,
        isAdmin,
        loading
    };

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }
    return (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <RouteContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>
            <RouterProvider router={router}>
                <ScrollToTop/>
            </RouterProvider>
            </ThemeProvider>
        </RouteContext.Provider>
        </QueryClientProvider>
    </React.StrictMode>
    );
};

export default AppRouter;