import { v4 as uuidv4 } from 'uuid';

function getFileExtension(filename){
    return filename.split('.').pop();
}
export class UnqiueFile{

    constructor(file, fileName=undefined) {
        this.fileName = fileName || file.name;
        this.uuid = uuidv4().toString() + "." + getFileExtension(this.fileName);
        this.file = new File([file], this.uuid, {
            type: file.type,
            lastModified: file.lastModified
        });    
    }
}