import { useEffect } from 'react';
import FileUpload, { defaultFileTypes } from '../../components/general/FileUpload';
import { Box, Typography } from '@mui/material';
import { Anta } from '../../Constants/Fonts';

export default function TechDocs({updateAgent, agent, updateComplete, onError}){
    
    useEffect(()=>{
        //updateComplete(Boolean(agent.un_tech_docs && Array.isArray(agent.un_tech_docs) && agent.un_tech_docs.length > 0))
        updateComplete(true)
    }, [agent])

    // useEffect(()=>{
    //     console.log('agent', agent)
    //     console.log([].map((a) => "test"))
    // },[agent])

    return(
        <Box>
            <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'  }}>Please Upload All Unstructured Technical Documents For Your Expert</Typography>
            <FileUpload 
            onReject={onError}
            displayStr={"place your files here"}
            files={(agent.un_tech_docs || [])}
            accept={defaultFileTypes}
            onUpdate={(newFiles) => updateAgent("un_tech_docs", newFiles)} />
            
        </Box>
    )
}

