import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function SuccessSnackBar({message, ...props}){
    return(
        <Snackbar 
        {...props}
        action={<CloseAction handleClose={props.onClose}/>}>
            <Alert
            onClose={props.onClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

function CloseAction({handleClose}){
    return(
      <>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </>
    )
}