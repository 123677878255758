class Expert{
    static maxId = 1;

    constructor({
        name = "John Doe",
        title,
        role,
        pitches,
        image,
        expertise,
        about,
        intro,
        backendId = 1,
        voice
      } = {}) {
        this.name = name;
        this.title = title;
        this.role = role;
        this.pitches = pitches;
        this.image = image;
        this.expertise = expertise;
        this.about = about;
        this.intro = intro
        this.id = Expert.maxId;
        this.backendId = backendId;
        this.voice = voice
        Expert.maxId++;
      }
}

export const dummyExperts = [
    new Expert({
        backendId: 1,
        name: "Alex Mercer",
        title: "Chief Information Security Officer (CISO)",
        role: "Cyber Security Expert",
        pitches: ["NIST Standards", "Supply Chain Risk", "Enterprise Risk Management"],
        image: require('../assets/images/buisness.jpg'),
        expertise: "Cyber Security Expert",
        about:"Alex Mercer is a seasoned information security expert with over two decades of experience in the field. Formerly a Chief Information Security Officer (CISO) at multiple Fortune 500 companies, Alex has successfully spearheaded the development and implementation of robust security strategies to safeguard digital assets and protect sensitive information. His wealth of experience encompasses a diverse range of industries, including finance, technology and healthcare, allowing him to develop a comprehensive understanding of the unique security challenges faced by each sector. Now, as an independent consultant, Alex brings his wealth of knowledge and expertise to organizations seeking to enhance their security posture, providing guidance on risk management, compliance, and cutting-edge cyber defense strategies."
    }),
    new Expert({
        name: "Jordan Lee",
        title: "Incident Response Specialist",
        image: require('../assets/images/tech_worker.jpg'),
        expertise: "Cyber Security Expert",
        about: "Jordan Lee excels in high-pressure environments as an Incident Response Specialist. Known for their quick thinking and problem-solving skills, Jordan effectively manages and resolves security breaches, minimizing damage and restoring operations swiftly. With a background in forensic analysis and a deep understanding of attack vectors, Jordan is adept at tracing cyber incidents to their source and implementing preventive measures to thwart future attacks."
    }),
    new Expert({
        name: "Morgan Patel",
        title: "Ethical Hacker",
        image: require("../assets/images/john_doe.jpg"),
        expertise: "Cyber Security Expert",
        about: "Morgan Patel is a renowned Ethical Hacker with a passion for uncovering security flaws before malicious actors can exploit them. Utilizing a combination of penetration testing, vulnerability assessments, and social engineering tactics, Morgan provides invaluable insights to organizations looking to strengthen their defenses. Their proactive approach and creative problem-solving techniques make them a key asset in the fight against cybercrime."
    }),
    new Expert({
        backendId: 2,
        name: "Emily Carter",
        title: "CFTC Regulatory Expert",
        pitches: [
          "Led regulatory reforms",
          "Expert in derivatives markets",
          "Industry thought leader"
        ],
        image: require('../assets/images/lawyer_female.jpg'),
        role: "CFTC Expert",
        expertise: "Regulatory Expert",
        voice: "nova",
        intro: "Hi, I am a CFTC Expert. How may I help you?",
        about: "Ms. Carter advises clients on regulatory strategy, risk management, and compliance matters in over-the-counter (OTC) derivatives. She  has over two decades of experience in regulatory compliance matters. An attorney by training, she worked in regulatory operations of a global investment bank, where she was responsible for compliance with the Bank Holding Company Act. Subsequently, she transitioned to securities operations, where she managed a client implementation team responsible for ensuring that all regulatory requirements are met during the onboarding process of client relationships for the institutional business of the firm. Ms. Carter went on to work for a Self Regulatory Organization in the OTC Derivatives department, where she provided regulatory oversight of provisionally registered U.S. and non-U.S. swap dealers. She is an expert in CFTC rules."
      }),
    new Expert({
        name: "Robert Langford",
        title: "Director of Market Regulation, SEC",
        pitches: [
          "Oversaw market structure initiatives",
          "Key figure in compliance",
          "Influential in securities law"
        ],
        image: require('../assets/images/lawyer_male.jpg'),
        expertise: "Regulatory Expert",
        about: "Robert Langford is a former Director of Market Regulation at the Securities and Exchange Commission, where he played a key role in overseeing market structure and compliance initiatives. His expertise in securities law and market regulation has positioned him as a leading figure in ensuring market integrity and investor protection."
    }),
    new Expert({
        name: "Sarah Mitchell",
        title: "Chief Risk Officer, OCC",
        pitches: [
          "Expert in banking supervision",
          "Advanced risk management strategies",
          "Strategic regulatory advisor"
        ],
        image: require("../assets/images/female_professional.jpg"),
        expertise: "Regulatory Expert",
        about: "Sarah Mitchell served as the Chief Risk Officer at the Office of the Comptroller of the Currency, where she was instrumental in developing and implementing advanced risk management strategies for national banks. Her extensive knowledge in banking supervision and regulatory affairs has made her a key advisor in shaping effective and resilient banking practices."
    })

]

export const testEmails = ['kyle.lewis@seqtechnology.com', 'kevin.weng@seqtechnology.com', 'shash@seqtechnology.com']


export const expertiseOptions = [
    {
        "name": "Account-Based Marketing Specialist"
    },
    {
        "name": "Accounts Payable Specialist"
    },
    {
        "name": "Accounts Receivable Specialist"
    },
    {
        "name": "Activity-Based Costing Specialist"
    },
    {
        "name": "Actuary"
    },
    {
        "name": "Admiralty Lawyer"
    },
    {
        "name": "Affiliate Marketing Manager"
    },
    {
        "name": "AI Ethics Consultant"
    },
    {
        "name": "Algorithmic Trader"
    },
    {
        "name": "Allergist"
    },
    {
        "name": "Anesthesiologist"
    },
    {
        "name": "Antitrust Lawyer"
    },
    {
        "name": "Appellate Lawyer"
    },
    {
        "name": "Art Law Attorney"
    },
    {
        "name": "Artificial Intelligence Specialist"
    },
    {
        "name": "Auditor"
    },
    {
        "name": "Augmented Reality Developer"
    },
    {
        "name": "Autonomous Vehicle Engineer"
    },
    {
        "name": "Aviation Law Attorney"
    },
    {
        "name": "Bankruptcy Lawyer"
    },
    {
        "name": "Behavioral Economics Consultant"
    },
    {
        "name": "Behavioral Finance Analyst"
    },
    {
        "name": "Bioinformatics Specialist"
    },
    {
        "name": "Blockchain Accountant"
    },
    {
        "name": "Blockchain Developer"
    },
    {
        "name": "Bookkeeper"
    },
    {
        "name": "Brand Strategist"
    },
    {
        "name": "Branding Consultant"
    },
    {
        "name": "Budget Analyst"
    },
    {
        "name": "Business Intelligence Analyst"
    },
    {
        "name": "Business Valuation Specialist"
    },
    {
        "name": "Cardiologist"
    },
    {
        "name": "Certified Public Accountant"
    },
    {
        "name": "Civil Rights Lawyer"
    },
    {
        "name": "Cloud Architect"
    },
    {
        "name": "Cloud Security Specialist"
    },
    {
        "name": "Commodities Trader"
    },
    {
        "name": "Community Manager"
    },
    {
        "name": "Compliance Officer"
    },
    {
        "name": "Computer Vision Engineer"
    },
    {
        "name": "Consolidation Accountant"
    },
    {
        "name": "Construction Lawyer"
    },
    {
        "name": "Content Marketing Manager"
    },
    {
        "name": "Conversational Marketing Specialist"
    },
    {
        "name": "Conversion Rate Optimizer"
    },
    {
        "name": "Copywriter"
    },
    {
        "name": "Corporate Governance Lawyer"
    },
    {
        "name": "Corporate Lawyer"
    },
    {
        "name": "Cost Accountant"
    },
    {
        "name": "Cost Estimator"
    },
    {
        "name": "Credit Analyst"
    },
    {
        "name": "Credit Risk Analyst"
    },
    {
        "name": "Criminal Defense Lawyer"
    },
    {
        "name": "Cryptocurrency Accountant"
    },
    {
        "name": "Customer Experience Manager"
    },
    {
        "name": "Customer Retention Specialist"
    },
    {
        "name": "Cyber Security Expert"
    },
    {
        "name": "Cybersecurity Law Attorney"
    },
    {
        "name": "Data Engineer"
    },
    {
        "name": "Data Privacy Compliance Specialist"
    },
    {
        "name": "Data Scientist"
    },
    {
        "name": "Database Administrator"
    },
    {
        "name": "Demand Generation Manager"
    },
    {
        "name": "Derivatives Trader"
    },
    {
        "name": "Dermatologist"
    },
    {
        "name": "DevOps Engineer"
    },
    {
        "name": "Digital Marketing Specialist"
    },
    {
        "name": "Distressed Debt Analyst"
    },
    {
        "name": "Edge Computing Specialist"
    },
    {
        "name": "Education Law Attorney"
    },
    {
        "name": "Email Marketing Specialist"
    },
    {
        "name": "Embedded Systems Engineer"
    },
    {
        "name": "Endocrinologist"
    },
    {
        "name": "Energy Law Attorney"
    },
    {
        "name": "Entertainment Lawyer"
    },
    {
        "name": "Environmental Lawyer"
    },
    {
        "name": "Equity Research Analyst"
    },
    {
        "name": "ESG (Environmental, Social, Governance) Analyst"
    },
    {
        "name": "ESG Reporting Specialist"
    },
    {
        "name": "Estate Planning Attorney"
    },
    {
        "name": "Experiential Marketing Manager"
    },
    {
        "name": "Family Law Attorney"
    },
    {
        "name": "Financial Analyst"
    },
    {
        "name": "Financial Controller"
    },
    {
        "name": "Financial Modeling Analyst"
    },
    {
        "name": "Financial Planner"
    },
    {
        "name": "Fintech Product Manager"
    },
    {
        "name": "First Amendment Lawyer"
    },
    {
        "name": "Fixed Asset Accountant"
    },
    {
        "name": "Food and Drug Law Attorney"
    },
    {
        "name": "Forensic Accountant"
    },
    {
        "name": "Forensic Economics Specialist"
    },
    {
        "name": "Forex Trader"
    },
    {
        "name": "Full Stack Developer"
    },
    {
        "name": "Gastroenterologist"
    },
    {
        "name": "Geriatrician"
    },
    {
        "name": "Governmental Accounting Specialist"
    },
    {
        "name": "Growth Hacker"
    },
    {
        "name": "Healthcare Law Attorney"
    },
    {
        "name": "Hedge Fund Manager"
    },
    {
        "name": "Hematologist"
    },
    {
        "name": "IFRS Specialist"
    },
    {
        "name": "Immigration Lawyer"
    },
    {
        "name": "Immunologist"
    },
    {
        "name": "Infectious Disease Specialist"
    },
    {
        "name": "Influencer Marketing Manager"
    },
    {
        "name": "Information Security Analyst"
    },
    {
        "name": "Intellectual Property Lawyer"
    },
    {
        "name": "Internal Auditor"
    },
    {
        "name": "International Trade Lawyer"
    },
    {
        "name": "Internet of Things (IoT) Specialist"
    },
    {
        "name": "Interventional Radiologist"
    },
    {
        "name": "Inventory Accountant"
    },
    {
        "name": "Investment Advisor"
    },
    {
        "name": "Investment Banker"
    },
    {
        "name": "IT Project Manager"
    },
    {
        "name": "Labor Law Attorney"
    },
    {
        "name": "Lease Accountant"
    },
    {
        "name": "Loan Officer"
    },
    {
        "name": "Machine Learning Engineer"
    },
    {
        "name": "Management Accountant"
    },
    {
        "name": "Managerial Accountant"
    },
    {
        "name": "Market Research Analyst"
    },
    {
        "name": "Marketing Analytics Specialist"
    },
    {
        "name": "Marketing Automation Specialist"
    },
    {
        "name": "Marketing Data Analyst"
    },
    {
        "name": "Marketing Operations Manager"
    },
    {
        "name": "Marketing Technology Manager"
    },
    {
        "name": "Medical Geneticist"
    },
    {
        "name": "Medical Malpractice Lawyer"
    },
    {
        "name": "Merger Integration Accountant"
    },
    {
        "name": "Mergers and Acquisitions Analyst"
    },
    {
        "name": "Mergers and Acquisitions Lawyer"
    },
    {
        "name": "Micro-Moments Marketing Strategist"
    },
    {
        "name": "Mobile App Developer"
    },
    {
        "name": "Mortgage Broker"
    },
    {
        "name": "Natural Language Processing Engineer"
    },
    {
        "name": "Neonatologist"
    },
    {
        "name": "Nephrologist"
    },
    {
        "name": "Network Administrator"
    },
    {
        "name": "Neurologist"
    },
    {
        "name": "Neuromarketing Specialist"
    },
    {
        "name": "Obstetrician"
    },
    {
        "name": "Omnichannel Marketing Specialist"
    },
    {
        "name": "Oncologist"
    },
    {
        "name": "Ophthalmologist"
    },
    {
        "name": "Orthopedic Surgeon"
    },
    {
        "name": "Otolaryngologist"
    },
    {
        "name": "Pain Management Specialist"
    },
    {
        "name": "Palliative Care Specialist"
    },
    {
        "name": "Patent Attorney"
    },
    {
        "name": "Pay-Per-Click Specialist"
    },
    {
        "name": "Payroll Specialist"
    },
    {
        "name": "Pediatrician"
    },
    {
        "name": "Plastic Surgeon"
    },
    {
        "name": "Portfolio Manager"
    },
    {
        "name": "Precision Medicine Specialist"
    },
    {
        "name": "Private Banking Advisor"
    },
    {
        "name": "Private Equity Associate"
    },
    {
        "name": "Product Marketing Manager"
    },
    {
        "name": "Programmatic Advertising Specialist"
    },
    {
        "name": "Psychiatrist"
    },
    {
        "name": "Public Relations Specialist"
    },
    {
        "name": "Pulmonologist"
    },
    {
        "name": "Quality Assurance Engineer"
    },
    {
        "name": "Quantitative Analyst"
    },
    {
        "name": "Quantum Computing Researcher"
    },
    {
        "name": "Quantum Cryptography Specialist"
    },
    {
        "name": "Quantum Machine Learning Researcher"
    },
    {
        "name": "Radiologist"
    },
    {
        "name": "Real Estate Lawyer"
    },
    {
        "name": "Regulatory Expert"
    },
    {
        "name": "Reproductive Endocrinologist"
    },
    {
        "name": "Revenue Recognition Accountant"
    },
    {
        "name": "Rheumatologist"
    },
    {
        "name": "Risk Manager"
    },
    {
        "name": "Robotics Engineer"
    },
    {
        "name": "Sarbanes-Oxley (SOX) Compliance Specialist"
    },
    {
        "name": "SEO Specialist"
    },
    {
        "name": "Social Media Manager"
    },
    {
        "name": "Software Developer"
    },
    {
        "name": "Space Law Attorney"
    },
    {
        "name": "Sports Law Attorney"
    },
    {
        "name": "Structured Finance Analyst"
    },
    {
        "name": "Surgeon"
    },
    {
        "name": "Sustainable Finance Specialist"
    },
    {
        "name": "Sustainable Investment Analyst"
    },
    {
        "name": "Systems Analyst"
    },
    {
        "name": "Tax Attorney"
    },
    {
        "name": "Tax Consultant"
    },
    {
        "name": "Telecommunications Engineer"
    },
    {
        "name": "Transfer Pricing Specialist"
    },
    {
        "name": "UI/UX Designer"
    },
    {
        "name": "Underwriter"
    },
    {
        "name": "Urologist"
    },
    {
        "name": "Vascular Surgeon"
    },
    {
        "name": "Venture Capitalist"
    },
    {
        "name": "Virtual Reality Developer"
    },
    {
        "name": "Voice Search Optimization Specialist"
    },
    {
        "name": "Wealth Manager"
    },
    {
        "name": "White Collar Crime Lawyer"
    }
]