import { Chip } from "@mui/material";

export default function EllipsisChip({maxWidth, ...props}){
    return(
        <Chip
            {...props}
            sx={{
            maxWidth: maxWidth,
            '& .MuiChip-label': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            }}
        />
    )
}