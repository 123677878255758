import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Dialog, DialogTitle, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ResponseDialog({loading, responseObj, onClose}){
    const [showCheckmark, setShowCheckmark] = useState(false);
    
    // This useEffect listens for when loading is finished and then shows the checkmark
    useEffect(() => {
        console.log('responseObj', responseObj)
        if (!loading && responseObj?.success) {
            setShowCheckmark(true);
        }else{
            setShowCheckmark(false)
        }
    }, [loading, responseObj]);

    const getTitle = () => {
        if (loading || !responseObj) {
            return "Processing your request";
        }
        if (!responseObj.success) {
            return "An error occurred";
        } else if (responseObj.success) {
            return "Your request was sent!";
        }
        return "Processing your request";
    }

    return (
        <Dialog 
            open={loading || responseObj} 
            onClose={onClose} 
            fullWidth 
            maxWidth="md"
        >
            <DialogTitle align="center">{getTitle()}</DialogTitle>
            <Box sx={{
                height: '40rem', 
                maxHeight: '90vh', 
                padding: '1%', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            {
                loading ? (
                    <CircularProgress size={60} />
                ) : (
                    <Box sx={{height: '100%', width: '100%', display: 'flex', flexDirection: "column", justifyContent: showCheckmark ? "space-between" : "center"}}>
                        <Box 
                            sx={{
                                flex: 1, 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex', 
                                flexDirection:'column',
                                wordBreak: 'break-word',
                                
                            }}
                        >
                            <Box sx={{
                                transition: 'opacity 0.5s ease', // For smooth transition
                                opacity: showCheckmark ? 1 : 0, // Initially hidden
                                }}>

                            {showCheckmark && (
                                <CheckCircleIcon 
                                    sx={{ 
                                        fontSize: 120, 
                                        color: 'green', 
                                        transition: 'transform 0.5s ease', 
                                        transform: showCheckmark ? 'scale(1)' : 'scale(0)' 
                                    }}
                                />
                            )}
                            </Box>
                        <Typography align="center">
                            {responseObj && responseObj.message}
                        </Typography>
                        </Box>
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                )
            }
            </Box>
        </Dialog>
    )
}
