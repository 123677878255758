import { Save } from "@mui/icons-material"
import { Autocomplete, Box, Button, Dialog, IconButton, Slider, Snackbar, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { AI_INTERVIEW_PROMPT, caseStudyPrompt } from "../../Constants/Prompts"
import { getUserEmail } from "../../util/Firebase"
import SuccessSnackBar from "../../components/general/SuccessSnackbar"
import ErrorSnackBar from "../../components/general/ErrorSnackBar"

export default function IntakeSettings({onError, onUpdate=()=>{}, promptAi="inflection_3_productivity", interviewAi="inflection_3_pi",
  openSettings=false, onClose=()=>{} }){
    const [currentAi, setCurrentAi] = useState(promptAi)
    const [currentInterviewerAi, setCurrentInterviewerAi] = useState(interviewAi)
    const [aiOptions, setAiOptions] = useState([])
    const [aiPrompt, setAiPrompt] = useState(caseStudyPrompt)
    const [interviewPrompt, setInterviewPrompt] = useState(AI_INTERVIEW_PROMPT)
    const [currentTab, setCurrentTab] = useState("chat")

    const [interviewerTemp, setInterviewerTemp] = useState(1)
    const [summarizerTemp, setSummarizerTemp] = useState(0)

    const [saveAlert, setSaveAlert] = useState(false)
    const [errorSave, setErrorSave] = useState(false)
    
    const handleUpdate = () => {
      console.log('updating')
      onUpdate({aiPrompt, currentAi, currentInterviewerAi, 
        interviewPrompt, interviewerTemp, summarizerTemp})
    }

    useEffect(()=>{
      setSaveAlert(false)
      setErrorSave(false)
      getPrompt()
    }, [openSettings])

    useEffect(()=>{
        console.log('test')
        handleUpdate()
    },[aiPrompt, interviewPrompt, currentAi, currentInterviewerAi,
      summarizerTemp, interviewerTemp
    ])

    const getPrompt = async () => {
        try{
          const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
          {
            headers:{
              "Authorization": getUserEmail()
            }
          })
  
          if(!resp.ok){
            //if response fails, do nothing
            //we will go to the default query
            return
          }
  
          const json = await resp.json()
          setAiPrompt(json.summarize_prompt || caseStudyPrompt)
          setCurrentAi(json.intake_structured_model || "inflection_3_pi")
          setCurrentInterviewerAi(json.interviewer_model || 'inflection_3_productivity')
          setInterviewPrompt(json.interviewer_prompt || AI_INTERVIEW_PROMPT)
          setInterviewerTemp(json.interview_temp ?? 1)
          setSummarizerTemp(json.summary_temp ?? 1)
        }
        catch(e){
          console.error(e)
          onError(e.message)
        }
      }

    const getAiOptions = async () => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/studies/aiOptions`)
        const json = await resp.json()
        if(!resp.ok)
          throw new Error(json.message)

        setAiOptions(json.options)
      }catch(e){
        onError(e.message)
      }
      
    }
    
    useEffect(()=>{
        getPrompt()
        getAiOptions()
    },[])

    const updateAiPrompt = async (newPrompt=aiPrompt) => {
      console.log('updateAI')
        try{
          const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
          {
            method: "PUT",
            headers:{
              "Authorization": getUserEmail(),
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              summarize_prompt: newPrompt,
              intake_structured_model: currentAi,
              interviewer_prompt: interviewPrompt,
              interviewer_model: currentInterviewerAi,
              interview_temp: interviewerTemp,
              summary_temp: summarizerTemp
            })
          })
          
          const json = await resp.json()
          if(!resp.ok){
            //if response fails, do nothing
            //we will go to the default query
            throw new Error(json.error)
          }
          
          console.log('new models', json.interviewer_model, json.intake_structured_model)
          
          setAiPrompt(json.summarize_prompt || caseStudyPrompt)
          setCurrentAi(json.intake_structured_model || "inflection_3_pi")
          setCurrentInterviewerAi(json.interviewer_model || 'inflection_3_productivity')
          setInterviewPrompt(json.interviewer_prompt || AI_INTERVIEW_PROMPT)
          setInterviewerTemp(json.interview_temp ?? 1)
          setSummarizerTemp(json.summary_temp ?? 1)
          if(openSettings)
            setSaveAlert(true)
        }
        catch(e){
          console.error(e)
          onError(e.message)
          if(openSettings)
            setErrorSave(true)
        }
    }
    
    const handleClose = () => {
      updateAiPrompt()
      onClose()
    }

    return (
      <Dialog
      open={openSettings} 
          onClose={handleClose}
          fullWidth 
          maxWidth="md"
      >
      
      <Box sx={{paddingY:'1rem'}}>
      <SuccessSnackBar open={saveAlert} onClose={() => setSaveAlert(false)} message="Settings Saved!"
        autoHideDuration={2000}/>
      <ErrorSnackBar open={errorSave} onClose={()=>setErrorSave(false)} message="Failed to save settings, please try again"
        autoHideDuration={2000}/>
        <Tabs
        variant="fullWidth"
        onChange={(_, v) => setCurrentTab(v)}
        value={currentTab}>
          <Tab value="chat" label="Interviewer Settings"/>
          <Tab value="structured" label="Summarizer Settings"/>
        </Tabs>
        <Box sx={{padding:'1%'}}>
          {
            currentTab === 'structured' ? 
            <>
            <Autocomplete
            options={aiOptions}
            onChange={(e, value) => setCurrentAi(value)}
            value={currentAi}
            fullWidth
            renderInput={(params) => <TextField {...params}  label="Current Summarizer LLM"/>}
          />
          <Typography>Temperature</Typography>
          <Box sx={{paddingX:'2%', paddingY:'1%'}}>
          <Slider
              value={summarizerTemp}
              onChange={(e, newValue) => setSummarizerTemp(newValue)}
              min={0}
              max={1}
              step={0.05}
              valueLabelDisplay="auto"
            />
          </Box>
          <TextField
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
              fullWidth
              multiline
              />
            </>
            :
            <>
            <Autocomplete
            options={aiOptions}
            onChange={(e, value) => setCurrentInterviewerAi(value)}
            value={currentInterviewerAi}
            fullWidth
            renderInput={(params) => <TextField {...params}  label="Current Interviewer LLM"/>}
          />
          <Typography>Temperature</Typography>
          <Box sx={{paddingX:'2%', paddingY:'1%'}}>
          <Slider
              label="Temperature"
              value={interviewerTemp}
              onChange={(e, newValue) => setInterviewerTemp(newValue)}
              min={0}
              max={1}
              step={0.05}
              valueLabelDisplay="auto"
            />
          </Box>
          <TextField
              value={interviewPrompt}
              onChange={(e) => setInterviewPrompt(e.target.value)}
              fullWidth
              multiline
              />
            </>
          }
          
          </Box>
          <Box sx={{display:'flex', width:'100%', justifyContent:'center'}}>
        <IconButton 
        fullWidth
        onClick={(e) => {e.preventDefault(); updateAiPrompt()}}>
            <Save/>
        </IconButton> 
        </Box>
        </Box>
        <Button onClick={handleClose}>Close</Button>
      </Dialog>
        
    )
}