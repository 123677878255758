import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { expertiseOptions } from "../../Constants/DummyInfo";
import { Anta } from "../../Constants/Fonts";
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {motion} from 'framer-motion'

import alloy from '../../assets/voices/alloy.mp3'
import echo from '../../assets/voices/echo.mp3'
import fable from '../../assets/voices/fable.mp3'
import nova from '../../assets/voices/nova.mp3'
import onyx from '../../assets/voices/onyx.mp3'
import shimmer from '../../assets/voices/shimmer.mp3'
import { validateEmail } from "../../util/Checker";

export default function ExpertForm({updateAgent, agent, updateComplete, disabled=false}){

    const fields = [
        {name: "name", title: "Name", props:{required:true, placeholder: "John doe", disabled:disabled}, size: 6},
        {name: "title", title: "Title", props:{required:true, placeholder: "Incident Response Specialist", disabled:disabled}, size: 6},
        {name: "about", title: "About", props:{multiline:true, required:true, rows:5, placeholder: "A little bio about your expert", disabled:disabled}, size:12}
    ]

    const theme = useTheme()

    //index of current choice
    const [currentAudio, setCurrentAudio] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [searchString, setSearchString] = useState("")

    const voiceOptions = useRef([
        {
            name: 'Alloy',
            audio: new Audio(alloy)
        },
        {
            name: "Echo",
            audio: new Audio(echo)
        },
        {
            name: "Fable",
            audio: new Audio(fable)
        },
        {
            name: "Nova",
            audio: new Audio(nova)
        },
        {
            name: "Onyx",
            audio: new Audio(onyx)
        },
        {
            name: "Shimmer",
            audio: new Audio(shimmer)
        }
    ])

    //trigger code for when the audio ends
    useEffect(() => {
        voiceOptions.current.forEach((voice) => {
          voice.audio.addEventListener('ended', () => {
            setIsPlaying(false)
          });
        });
      
        //clean up the event listeners when the component unmounts
        return () => {
          voiceOptions.current.forEach((voice) => {
            voice.audio.removeEventListener('ended', () => {});
          });
        };
      }, []);

    const handlePlay = (index) => {
        if(index === currentAudio && !voiceOptions.current[currentAudio].audio.paused){
            setIsPlaying(false)
            voiceOptions.current[currentAudio].audio.pause()
            voiceOptions.current[currentAudio].audio.currentTime = 0;
            return;
        }

        voiceOptions.current[currentAudio].audio.pause()
        voiceOptions.current[currentAudio].audio.currentTime = 0;

        setCurrentAudio(index)
        setIsPlaying(true)
        voiceOptions.current[index].audio.play()

    }
   

    useEffect(()=>{
        if(!updateComplete)
            return

        let complete = true
        if(!agent.email || !validateEmail(agent.email)){
            updateComplete(false)
            return;
        }
        //see if there are any incomplete fields
        for(const f of fields){
            if(f.props.required && !agent[f.name]){
                updateComplete(false)
                return;
            }
        }

        complete = Boolean(complete && agent.expertise)

        updateComplete(complete)
    },[agent])


    const getAudioBorder = (index) => {
        if(!agent.voice){
            return index === 0 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent'
        }
        return voiceOptions.current[index].name.toLowerCase() === agent.voice.toLowerCase() ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent'
    }

    return(
        <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        {!disabled && <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'  }}>Enter In Your Information for Your Custom Expert Here</Typography>}
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField label="Contact Email" value={agent.email || ""} onChange={(e) => updateAgent("email", e.target.value)} fullWidth required
                error={agent.email && !validateEmail(agent.email)}/>
            </Grid>
            {fields.map((f, index) => (
                <Grid item xs={12} md={f.size || 12}>
                    <TextField {...f.props} label={f.title || f.name} value={agent[f.name]}  onChange={(e) => updateAgent(f.name, e.target.value)} fullWidth/>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Autocomplete
                options={expertiseOptions}
                getOptionLabel={(option) => option.name}
                value={agent.expertise}
                required
                disabled={disabled}
                onChange={(e, v) => updateAgent('expertise', v)}
                renderInput={(params) => 
                <TextField {...params} label="Expertise" placeholder="Cybersecurity Expert" required/>}
                />
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
            multiple
            freeSolo
            value={agent.keywords || []}
            options={[]}
            inputValue={searchString}
            onChange={(e, v) => {setSearchString(""); updateAgent('keywords', v)}}
            onInputChange={(e) => setSearchString(e.target.value)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Keywords"
                    placeholder="some quick keywords about your expert"
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position="end" sx={{mr:1}}>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    if (searchString) {
                                        console.log('searchString', searchString)
                                        updateAgent('keywords', [...(agent.keywords || []), searchString]);
                                        setSearchString("")
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                        </>
                    ),
                    }}
                />
            )}
        />
        <Grid item container xs={12} spacing={1} sx={{marginTop:'0.5rem'}}>
    {voiceOptions.current.map((v, index) => (
        <Grid 
            item 
            container 
            xs={6} 
            md={4} 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexDirection: 'column' 
            }}
            key={index}
        >
            <motion.div
                whileHover={{ backgroundColor: 'rgba(128, 128, 128, 0.2)' }} // Grey background on hover
                animate={{
                    border: getAudioBorder(index)
                }}
                transition={{ duration: 0.3 }} // Smooth transition effect
                style={{
                    borderRadius: '8px', // Optional for rounded corners
                    width:'100%',
                    display:'flex',
                    cursor:'pointer',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                onClick={() => updateAgent('voice', voiceOptions.current[index].name.toLowerCase())}
            >
                <Typography>{v.name}</Typography>
                <IconButton onClick={() => handlePlay(index)}>
                    {currentAudio !== index || !isPlaying ? (
                        <PlayCircleIcon />
                    ) : (
                        <StopCircleIcon />
                    )}
                </IconButton>
            </motion.div>
        </Grid>
    ))}
</Grid>

        </Grid>
        </Grid>
        </Box>
    )
}