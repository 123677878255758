import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import BackgroundTemplate from "../../components/general/BackgroundTemplate";
import IntakeChat from "./IntakeChat";
import { useEffect, useRef, useState } from "react";
import { ChatMessage, ChatRoles } from "../../classes/ChatMessage";
import { getUserEmail, makeAuthenticatedRequest } from "../../util/Firebase";
import { Close, Download, Edit, Save } from "@mui/icons-material";
import Markdown from "react-markdown";
import { AI_INTERVIEW_PROMPT, caseStudyPrompt } from "../../Constants/Prompts";
import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import MarkdownIt from 'markdown-it';
import IntakeSettings from "./IntakeSettings";
import { openingCreate } from "./Constants";

export default function Intake({handleConfirmIntake=()=>{}, displayOptions=true, openingMessage=openingCreate}){
    const [logs, setLogs] = useState([
        new ChatMessage(openingMessage)
      ])

    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStruct, setLoadingStruct] = useState(false)
    const [error, setError] = useState(null);
    const [aiOptions, setAiOptions] = useState([])
    const [currentAi, setCurrentAi] = useState("inflection_3_pi")
    const [currentPromptAi, setCurrentPromptAi] = useState("inflection_3_productivity")
    const [aiPrompt, setAiPrompt] = useState(caseStudyPrompt)
    const [interviewPrompt, setInterviewPrompt] = useState(AI_INTERVIEW_PROMPT)
    const [downloading, setDownloading] = useState(false)
    
    const [interviewerTemp, setInterviewerTemp] = useState(1)
    const [summarizerTemp, setSummarizerTemp] = useState(0)

    const getSessionId = () => {

      return String(uuidv4())
    }

    const sessionId = useRef(getSessionId())
    //load inital prompt
    useEffect(()=>{
      getPrompt()
      if(displayOptions){
        getAiOptions()
      }
    },[])

    const getAiOptions = async () => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/studies/aiOptions`)
        const json = await resp.json()
        if(!resp.ok)
          throw new Error(json.message)

        setAiOptions(json.options)
      }catch(e){
        setError(e.message)
      }
      
    }

    const submitAudio = (transcribedText) => {
      setLogs(prevLogs => [...prevLogs, new ChatMessage(transcribedText, ChatRoles.USER)])
      generateCaseStudy(transcribedText)
    }

    const handleSubmit = (p) => {
      setLogs(prevLogs => [...prevLogs, new ChatMessage(p, ChatRoles.USER)])
      generateCaseStudy(p)
    }

    

    const generateCaseStudy = async (p) => {
      let accumulator = ""
      try {
        setIsLoading(true);
        setError(null);
        const resp = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_URL}/studies/generateCaseStudyExpert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: String(sessionId.current),
            prompt: p,
            interview_prompt: interviewPrompt,
            llm: currentAi,
            temperature: interviewerTemp
          }),
        });
  
        if (!resp.ok) {
          throw new Error('Failed to generate case study');
        }
  
        const reader = resp.body.getReader();
        const decoder = new TextDecoder();
  
        while (true) {
          const { done, value } = await reader.read();
          
          if (done) {
            break;
          }
  
          // Decode the stream and append to existing response
          const text = decoder.decode(value);
          accumulator += text;
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        setLogs(prevLogs => [...prevLogs, new ChatMessage(accumulator)])
      }
    };

    const handleGenerate = async () => {
      setLoadingStruct(true)
      try{
        const resp = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_URL}/studies/generateStructuredData`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: String(sessionId.current), // You should generate this dynamically
              edit_prompt: aiPrompt,
              llm: currentPromptAi,
              temperature: summarizerTemp
            })
          }
        )
  
        const body = await resp.json()
        
        setResponse({success:resp.ok, message:body.text})
      }catch(e){
        console.error(e)
        setError(e.message)
        setResponse({success: false, message:e.message})
      }finally{
        setLoadingStruct(false)
      }
      
    }
    const getPrompt = async () => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
        {
          headers:{
            "Authorization": getUserEmail()
          }
        })

        if(!resp.ok){
          return
        }

        const json = await resp.json()
        setAiPrompt(json.summarize_prompt || caseStudyPrompt)
        setCurrentPromptAi(json.intake_structured_model || "inflection_3_productivity")
        setCurrentAi(json.interviewer_model || 'inflection_3_pi')
        setInterviewPrompt(json.interviewer_prompt || AI_INTERVIEW_PROMPT)
        setInterviewerTemp(json.interview_temp ?? 1)
        setSummarizerTemp(json.summary_temp ?? 1)
      }
      catch(e){
        console.error(e)
        setError(e.message)
      }
    }

    const onDownload = async () => {
      setDownloading(true)
      makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/document/download_intake`,({
        method: "POST",
          headers:{
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            chat_logs: logs,
            structured_data: response.message,
          })
      })).then(async (resp) => {
        if(!resp.ok)
          throw new Error('something went wrong when downloading')
        const blob = await resp.blob();

        //create a temporary link element
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'generated_file.pdf'; // Set the file name
        document.body.appendChild(a);
        a.click();
        
        //clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        setDownloading(false)
      }).catch(e => {
        console.error("error", e)
        setDownloading(false)
      })
    };
    
    
    const handleConfirm = () => {
      handleConfirmIntake(sessionId.current)
      setResponse(null)
    }

    const manageAiSettings = ({aiPrompt, currentAi, currentInterviewerAi, 
      interviewPrompt, interviewerTemp, summarizerTemp}) => {
      setCurrentPromptAi(currentAi)
      setAiPrompt(aiPrompt)
      setCurrentAi(currentInterviewerAi)
      setInterviewPrompt(interviewPrompt)
      setInterviewerTemp(interviewerTemp)
      setSummarizerTemp(summarizerTemp)
    }

    return(
      <>
      <ConfirmModal loading={loadingStruct} onConfirm={handleConfirm} onRetry={handleGenerate}
        onClose={(e)=>{ e.preventDefault(); setResponse(null); setLoadingStruct(false)}} responseObj={response}
        aiPrompt={aiPrompt} setAiPrompt={setAiPrompt} onDownload={onDownload}
        currentAi={currentPromptAi} setCurrentAi={setCurrentPromptAi} aiOptions={aiOptions} onError={setError}
        updateSettings={manageAiSettings} downloading={downloading}
        />
        <IntakeChat submitAudio={submitAudio} handleSubmit={handleSubmit} handleGenerate={handleGenerate} 
        logs={logs} isLoading={isLoading} updateSettings={manageAiSettings} promptAi={currentPromptAi}
        interviewerAi={currentAi} interviewPrompt={interviewPrompt}/>
      </>
        
    )
}

export function ConfirmModal({loading, responseObj, onClose, onRetry, onConfirm, onError, 
  setAiPrompt, onDownload, setCurrentAi, currentAi, updateSettings, downloading}){
  const [showPrompt, setShowPrompt] = useState(false);
    // This useEffect listens for when loading is finished and then shows the checkmark

    const getTitle = () => {
        if (loading || !responseObj) {
            return "Summarizing Conversation";
        }
        if (!responseObj.success) {
            return "An error occurred";
        } else if (responseObj.success) {
            return "Conversation Summary";
        }
        return "Summarizing Conversation";
    }

    return (
        <Dialog 
            open={loading || responseObj} 
            onClose={onClose} 
            fullWidth 
            maxWidth="md"
        >
            <DialogTitle align="center">
              <Grid container spacing={0}>
                <Grid container item xs={12} sx={{justifyContent:'flex-end'}}>
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                {getTitle()}
                </Grid>
              </Grid>
            </DialogTitle>
            <Box sx={{
                height: '40rem', 
                maxHeight: '90vh', 
                padding: '1%', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            {
                loading ? (
                    <CircularProgress size={60} />
                ) : (
                    <Box sx={{height: '100%', width: '100%', display: 'flex', flexDirection: "column"}}>
                        <Box 
                            sx={{
                                flex: 1, 
                                display:'flex',
                                alignItems: 'center', 
                                flexDirection:'column',
                                justifyContent:'space-between'
                            }}
                        >
                        <Typography>
                          <Markdown>
                            {responseObj && responseObj.message}
                          </Markdown>
                        </Typography>
                        <IntakeSettings onError={onError} onUpdate={updateSettings} promptAi={currentAi} openSettings={showPrompt}
                          onClose={() => {setShowPrompt(false); onConfirm()}}
                        />
                        </Box>
                        <DialogActions>
                          <IconButton onClick={(e) => {e.preventDefault(); setShowPrompt(!showPrompt)}}>
                            <Edit/>
                          </IconButton>
                          <Box>
                          {
                            !downloading ? 
                            <IconButton onClick={(e) => {e.preventDefault(); onDownload()}}>
                              <Download/>
                            </IconButton>
                            :
                            <CircularProgress/>
                          }
                          </Box>
                          
                          <>
                              <Button fullWidth onClick={(e) => onRetry()}>
                                Retry
                              </Button>
                              <Button fullWidth onClick={(e) => onConfirm()}>
                                Close
                              </Button>
                          </>
                          </DialogActions>
                    </Box>
                )
            }
            </Box>
        </Dialog>
    )
}