import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";

export default function IntakeWarning({open, onClose}){
    return(
        <Dialog
        open={open}
        onClose={onClose}
        fullWidth 
        maxWidth="md"
        >
             <DialogTitle align="center">
              <Grid container spacing={0}>
                <Grid container item xs={12} sx={{justifyContent:'flex-end'}}>
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                Uh Oh!
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    There doesn't seem to be enough data in your conversation to generate anything!  Please
                    continue to talk with the agent and tell it more about your experience.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={(e) => {e.preventDefault(); onClose()}}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}