import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Typography } from "@mui/material";

export default function IntakeHelp({open, onClose}){

    return(
        <Dialog
        open={open}
        onClose={onClose}
        fullWidth 
        maxWidth="md"
        >
             <DialogTitle align="center">
              <Grid container spacing={0}>
                <Grid container item xs={12} sx={{justifyContent:'flex-end'}}>
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                How to use Sinsen.ai's Intake
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        <Typography>Talk with the Agent</Typography>
                        <Typography>Use the microphone button and talk to the agent or type your response</Typography>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    )
}