import { Box, Typography } from "@mui/material"
import { doubleGradient, singleGradient } from "../../Constants/Colors"
import { StyledAppBar } from "./AppBar"
import { useTheme } from "@emotion/react"
import { Anta, fontSizes } from "../../Constants/Fonts"

export default function BackgroundTemplate({children, navbar=true, footer=true}){
    return(
    <Box sx={{background:{xs: singleGradient, md: doubleGradient}, minHeight:'100vh', maxWidth:'100vw', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
        <Box sx={{display:'flex', flexDirection:'column', flexGrow:1}}>
            {navbar && <StyledAppBar/>}
            {children}
        </Box>
        {footer && <Footer/>}
    </Box>
    )
}

export function Footer(){
    const theme = useTheme()
    return(
        <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', flexDirection:'row'}}>
            <Box sx={{paddingRight:'1rem'}}>
            <Typography sx={{fontSize:fontSizes.default-3, fontFamily:Anta.fontFamily, color: theme.palette.primary.main}}>Powered By Inflection.AI</Typography>
            </Box>
        </Box>
    )
}