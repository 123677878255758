import { useEffect, useRef, useState } from 'react';
import FileUpload from '../../components/general/FileUpload';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Anta, fontSizes } from '../../Constants/Fonts';
import RecordButton from '../../components/general/RecordButton';
import { ArrowUpward, Close, Edit, Save } from '@mui/icons-material';
import { getUserEmail, makeAuthenticatedRequest } from '../../util/Firebase';
import {ChatMessage, ChatRoles} from '../../classes/ChatMessage';
import Markdown from 'react-markdown';
import CustomButton from "../../components/general/CustomButton";
import ResponseDialog from '../../components/general/ResponseDialog';
import { caseStudyPrompt } from '../../Constants/Prompts';
import IntakeChat from '../Intake/IntakeChat';
import Intake from '../Intake/Intake';

export default function CaseStudies({updateAgent, agent, updateComplete, sessionId, onError, logs, setLogs}){
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStruct, setLoadingStruct] = useState(false)
    const [error, setError] = useState(null);
    
    const [tab, setTab] = useState("intake")
    const [aiPrompt, setAiPrompt] = useState(caseStudyPrompt)

    

    //load inital prompt
    useEffect(()=>{
      getPrompt()
    },[])


    useEffect(()=>{
        updateComplete(Boolean(agent.case_studies_session) ||
          Boolean(agent.case_studies && Array.isArray(agent.case_studies) && agent.case_studies.length > 0))
    }, [agent])

    const changeTab = (e, newValue) => {
        console.log("newValue", newValue)
        setTab(newValue)
    }

    const submitAudio = (transcribedText) => {
        setLogs(prevLogs => [...prevLogs, new ChatMessage(transcribedText, ChatRoles.USER)])
        generateCaseStudy(transcribedText)
    }

    const handleSubmit = (p) => {
        setLogs(prevLogs => [...prevLogs, new ChatMessage(p, ChatRoles.USER)])
        generateCaseStudy(p)
    }

    

    const generateCaseStudy = async (p) => {
      let accumulator = ""
      try {
        setIsLoading(true);
        setError(null);
  
        const resp = await makeAuthenticatedRequest(`${process.env.REACT_APP_API_URL}/studies/generateCaseStudyExpert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: String(sessionId), // You should generate this dynamically
            prompt: p // Customize this prompt
          }),
        });
  
        if (!resp.ok) {
          throw new Error('Failed to generate case study');
        }
  
        const reader = resp.body.getReader();
        const decoder = new TextDecoder();
  
        while (true) {
          const { done, value } = await reader.read();
          
          if (done) {
            break;
          }
  
          // Decode the stream and append to existing response
          const text = decoder.decode(value);
          accumulator += text;
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        console.log('response is ', accumulator)
        console.log('logs are', logs)
        setLogs(prevLogs => [...prevLogs, new ChatMessage(accumulator)])
      }
    };

    const handleGenerate = async () => {
      setLoadingStruct(true)
      try{
        const resp = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_API_URL}/studies/generateStructuredData`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: String(sessionId), // You should generate this dynamically
              edit_prompt: aiPrompt
            })
          }
        )
  
        const body = await resp.json()
        
        setResponse({success:resp.ok, message:body.text})
      }catch(e){
        console.error(e)
        setError(e.message)
        setResponse({success: false, message:e.message})
      }finally{
        setLoadingStruct(false)
      }
      
    }

    const handleConfirmIntake = () => {
      updateAgent('case_studies_session', sessionId)
      setResponse(null)
      updateAiPrompt()
    }

    const getPrompt = async () => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
        {
          headers:{
            "Authorization": getUserEmail()
          }
        })

        if(!resp.ok){
          //if response fails, do nothing
          //we will go to the default query
          return
        }

        const json = await resp.json()
        setAiPrompt(json.summarize_prompt)
      }
      catch(e){
        console.error(e)
        setError(e.message)
      }
    }

    const updateAiPrompt = async (newPrompt=aiPrompt) => {
      try{
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/settings`,
        {
          method: "PUT",
          headers:{
            "Authorization": getUserEmail(),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            summarize_prompt: newPrompt
          })
        })
        
        const json = await resp.json()
        if(!resp.ok){
          //if response fails, do nothing
          //we will go to the default query
          throw new Error(json.error)
        }

        
        setAiPrompt(json.summarize_prompt)
      }
      catch(e){
        console.error(e)
        setError(e.message)
      }
    }

    return (
      <Box sx={{ 
        height:0,
        flexGrow:1,
        display: 'flex', 
        flexDirection: 'column',
        maxWidth:'100%'
      }}>
        {/* Tabs section */}
        <Box sx={{ flexShrink: 0 }}>
          <Tabs 
            value={tab}
            onChange={changeTab}
            centered
          >
            <Tab label="Intake" value="intake"/>
            <Tab label="Upload Case Studies" value="upload"/>
          </Tabs>
        </Box>
  
        {/* Main content area */}
        {tab === 'upload' ? (
          <Box sx={{ 
            flex: 1,
            overflowY: 'auto',
            padding: 2
          }}>
            <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'}}>
              Please Upload All Case Studies For Your Expert
            </Typography>
            <FileUpload 
              boxProps={{minHeight:{xs:'5rem', md:'10rem'}}}
              onReject={onError}
              files={(agent.case_studies || [])}
              displayStr={"Place your ordered files here"}
              onUpdate={(newFiles) => updateAgent("case_studies", newFiles)} 
            />
          </Box>
        ) : tab === 'intake' ? (
          <>
          <Intake handleConfirmIntake={handleConfirmIntake}/>
        </>
        ) : (
          <Typography>Please refresh the page</Typography>
        )}
      </Box>
    );
}


export function ConfirmModal({loading, responseObj, onClose, onRetry, onConfirm, aiPrompt="", setAiPrompt, saveAiPrompt}){
  const [showPrompt, setShowPrompt] = useState(false);
    // This useEffect listens for when loading is finished and then shows the checkmark

    const getTitle = () => {
        if (loading || !responseObj) {
            return "Summarizing Conversation";
        }
        if (!responseObj.success) {
            return "An error occurred";
        } else if (responseObj.success) {
            return "Conversation Summary";
        }
        return "Summarizing Conversation";
    }

    return (
        <Dialog 
            open={loading || responseObj} 
            onClose={onClose} 
            fullWidth 
            maxWidth="md"
        >
            <DialogTitle align="center">
              <Grid container spacing={0}>
                <Grid container item xs={12} sx={{justifyContent:'flex-end'}}>
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                {getTitle()}
                </Grid>
              </Grid>
            </DialogTitle>
            <Box sx={{
                height: '40rem', 
                maxHeight: '90vh', 
                padding: '1%', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            {
                loading ? (
                    <CircularProgress size={60} />
                ) : (
                    <Box sx={{height: '100%', width: '100%', display: 'flex', flexDirection: "column"}}>
                        <Box 
                            sx={{
                                flex: 1, 
                                display:'flex',
                                alignItems: 'center', 
                                flexDirection:'column',
                                justifyContent:'space-between'
                            }}
                        >
                        <Typography>
                          <Markdown>
                            {responseObj && responseObj.message}
                          </Markdown>
                        </Typography>
                        {
                          showPrompt &&
                          <TextField
                          value={aiPrompt}
                          onChange={(e) => setAiPrompt(e.target.value)}
                          fullWidth
                          multiline
                          />
                        }
                        </Box>
                        <DialogActions>
                          <IconButton onClick={(e) => {e.preventDefault(); setShowPrompt(!showPrompt)}}>
                            <Edit/>
                          </IconButton>
                          {showPrompt && 
                          <IconButton onClick={(e) => {e.preventDefault(); saveAiPrompt()}}>
                            <Save/>
                          </IconButton>}
                          <>
                              <Button fullWidth onClick={(e) => onRetry()}>
                                Retry
                              </Button>
                              <Button fullWidth onClick={(e) => onConfirm()}>
                                Confirm
                              </Button>
                          </>
                          </DialogActions>
                    </Box>
                )
            }
            </Box>
        </Dialog>
    )
}

