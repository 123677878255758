import { useEffect } from 'react';
import FileUpload, { defaultExtensions } from '../../components/general/FileUpload';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { Anta } from '../../Constants/Fonts';
import { styled } from '@mui/material/styles';
import EllipsisChip from '../../components/general/EllipsisChip';
import { UnqiueFile } from '../../classes/UniqueFile';

export default function StructuredTech({updateAgent, agent, updateComplete, onError}){

    useEffect(()=>{
        //updateComplete(Boolean(agent.s_tech_docs && Array.isArray(agent.s_tech_docs) && agent.s_tech_docs.length > 0))
        updateComplete(true)
    }, [agent])

    const fileTypes = {
        //'image/*': [], // Allows all image types
        'text/csv': ['.csv'],
    }

    const metaFileTypes = defaultExtensions.join(',')

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const updateStructured = (newFiles) => {
        //remove metadata maps for files that no longer have their corresponding file

        updateAgent("s_tech_docs", newFiles)
    }


    const updateMetaMap = (key, fileList) => {
        console.log('test', key, fileList)
        const fArr = [...fileList]
        const arr = fArr.map(a => new UnqiueFile(a))
        let newMap = {...(agent?.meta_map || {})}
        newMap[key] = [...(newMap[key] || []), ...arr]
        updateAgent('meta_map', newMap)
    }

    const removeMetaMap = (key, index) => {
        let newMap = {...(agent?.meta_map || {})}
        let files = newMap[key] || []
        newMap[key] = [...files.slice(0, index), 
        ...files.slice(index + 1)]

        updateAgent('meta_map', newMap)
    }

    return(
        <Box>
            <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem' }}>Please Upload All Structured Technical Documents For Your Expert</Typography>
            <FileUpload 
            onReject={onError}
            files={(agent.s_tech_docs || [])}
            displayStr={"Place your ordered files here"}
            onUpdate={(newFiles) => updateStructured(newFiles)} 
            accept={fileTypes}/>
            <Grid container sx={{marginTop:'1rem'}}>
                {agent.s_tech_docs?.map((doc => (
                    <Grid item container sx={{flexDirection:'column'}} xs={6} sm={6} md={4}>
                        <Chip label={doc.fileName}/>

                        {agent?.meta_map?.[doc.uuid]?.map((file, index) => (
                            <Chip sx={{mt:'3px'}}
                            key={index} 
                            label={file.fileName}
                            onDelete={(e) => {
                                e.preventDefault();
                                removeMetaMap(doc.uuid, index);
                                }}
                            />
                        ))}
                        <Button component="label" disabled={Boolean(agent?.meta_map?.[doc.uuid]?.length > 0)}>
                            Upload Metadata
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => updateMetaMap(doc.uuid, event.target.files)}
                                multiple
                                accept={metaFileTypes}
                            />
                        </Button>
                    </Grid>
                )))}
                {/* <Box sx={{marginBottom:'0.5rem' }}>
                    <Typography align="center" sx={{fontFamily: Anta.fontFamily }}>Please Upload All The MetaData for Your Technical Documents</Typography>
                    <Box sx={{marginBottom:'2px'}}>
                    <Typography align="center" variant="body">
                        Metadata contains information about your tables such as the column names and the allowed values.  
                        Giving metadata allows the agent to more effetivley parse the technical documents given to it and provide more
                        accurate results.
                    </Typography>
                    </Box>
                </Box>
                <FileUpload 
                onReject={onError}
                displayStr={"place your files here"}
                files={(agent.metadata || [])}
                onUpdate={(newFiles) => updateAgent("metadata", newFiles)} /> */}
            </Grid>
        </Box>
    )
}

