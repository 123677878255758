
import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

export function useHover() {
  const [hovered, setHovered] = React.useState(false);
  const ref = React.useRef(null);

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);

  React.useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  return { hovered, ref };
}

export const useIsMdScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
}