import { Box } from "@mui/material";
import BackgroundTemplate from "../../components/general/BackgroundTemplate";
import Intake from "./Intake";
import { openingIntake } from "./Constants";

export default function intakePage({}){
    return (
        <BackgroundTemplate>
            <Box sx={{display:'flex', flex:1, justifyContent:'center' }}>
                <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:'column', padding:'1%', width:'90rem', maxWidth:'100%'}}>
                    <Intake openingMessage={openingIntake}/>
                </Box>
            </Box>
            
        </BackgroundTemplate>
    )
}