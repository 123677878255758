import { Component, createElement, useCallback, useEffect, useState } from "react";
import BackgroundTemplate from "../../components/general/BackgroundTemplate";
import { Box, Button, Grid, MobileStepper, Paper, Snackbar, Step, StepButton, Stepper, Typography, useTheme } from "@mui/material";
import ExpertForm from "./ExpertForm";
import CustomButton from "../../components/general/CustomButton";
import TechDocs from "./TechDocs";
import Confirm from "./Confirm";
import StructuredTech from "./StructuredTech";
import CaseStudies from "./CaseStudies";
import ResponseDialog from "../../components/general/ResponseDialog";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useIsMdScreen } from "../../util/hooks";
import { Anta, fontSizes } from "../../Constants/Fonts";
import { makeAuthenticatedRequest } from "../../util/Firebase";
import ErrorSnackBar from "../../components/general/ErrorSnackBar";
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage } from "../../classes/ChatMessage";

export default function CreateExpert() {
  const theme = useTheme()
  const [sessionId] = useState(uuidv4())
  const isMdScreen = useIsMdScreen();

  
  const handleFileError = (errors) => {
    console.log('errors', errors)

    let newErrorString = "Your files have the following errors:\n"

    for(const err of errors){
      newErrorString += `${err.file.name}: \n`
      for(const fileErr of err.errors){
        switch(fileErr.code){
          case 'file-invalid-type':
            newErrorString += `\tInvalid File Type\n`
            break;
          default:
            newErrorString += `\t${fileErr.message}\n`
            break;
        }
      }
    }

    setErrorString(newErrorString)
  }

  const handleError = (error) => {
    setErrorString(String(error))
  }

  const [chatLogs, setChatLogs] = useState([
    new ChatMessage(`Hello There! I'm here to assist you in creating some case studies of your own.  If you already have files containing your case studies, please use the other tab and upload them.  Otherwise, please tell me about your experience.`)
  ])

  const STEPS = [
    { name: 'Expert Info', Component: ExpertForm, additionalProps: {} },
    { name: 'Unstructured Technical Documents', Component: TechDocs, errorFunc: handleFileError, additionalProps: {} },
    { name: 'Structured Technical Documents', Component: StructuredTech, errorFunc: handleFileError, additionalProps: {}},
    { name: "Case Studies", Component:CaseStudies, errorFunc: handleFileError, additionalProps: {
      logs: chatLogs,
      setLogs: setChatLogs
    }},
    { name: 'Confirm', Component: Confirm, additionalProps: {} },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [agentInfo, setAgentInfo] = useState({});
  const [stepsCompleted, setStepsCompleted] = useState(STEPS.map(() => false));

  
  const resetForm = () => {
    setCurrentStep(0)
    setAgentInfo({})
    setStepsCompleted(STEPS.map(() => false))
  }

  const [respObject, setRespObject] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorString, setErrorString] = useState("")
  const [expertSubmitted, setExpertSubmitted] = useState(false)
  

  const updateAgentInfo = ((key, value) => {
    setAgentInfo(prevInfo => ({ ...prevInfo, [key]: value }));
    setExpertSubmitted(false)
  });

  const updateStepCompletion = useCallback((index, status) => {
    setStepsCompleted(prev => {
      const newStepsCompleted = [...prev];
      newStepsCompleted[index] = status;
      return newStepsCompleted;
    });
  }, []);
  
  const handleNext = () => setCurrentStep(prev => Math.min(prev + 1, STEPS.length - 1));
  const handleBack = () => setCurrentStep(prev => Math.max(prev - 1, 0));
  const handleStep = (step) => setCurrentStep(step);
  
  const readyToSubmit = () => {
    //see if everything except for the last element (confirm) is done
    return stepsCompleted.slice(0, -1).every(val => val === true) && stepsCompleted[stepsCompleted.length - 1] === false;
  }

  //used to warn user that clearing form will do stuff
  useEffect(() => {
    // Handler for beforeunload event
    const handleBeforeUnload = (e) => {
        // Standard way to show browser warning
        e.preventDefault();
        e.returnValue = '';
    };

    // Add event listener when component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  const handleSubmit = () => {

    const body = {...agentInfo}
    
    let structured = null
    if(body.s_tech_docs){
      structured = body.s_tech_docs.map(d => d.file)
      delete body.s_tech_docs
    }
    
    let unstructured = null
    if(body.un_tech_docs){
      unstructured = body.un_tech_docs.map(d => d.file)
      delete body.un_tech_docs
    }
    
    
    let studies = []
    if(body.case_studies){
      studies = body.case_studies.map(d => d.file)
      delete body.case_studies
    }
    
    
    //extract metadata from meta_map
    let metadata = []
    if(body.meta_map && !_.isEmpty(body.meta_map)){
      metadata = Object.values(body.meta_map).flat()
      //extract the file
      metadata = metadata.map(m => m .file)

      //extract uuids
      const reducedMeta = Object.keys(body.meta_map).reduce((acc, key) => {
        acc[key] = body.meta_map[key].map(f => f.uuid);
        return acc;
      }, {});
      body.meta_map = reducedMeta
      
    }

    delete body.metadata

    const formBody = new FormData();
    formBody.append('json_data', JSON.stringify(body))

    //append structured files
    if (structured && structured.length) {
      structured.forEach((file, index) => {
        formBody.append(`tech_docs`, file)
      })
      console.log('structured', structured)
    }
    
    if (metadata && metadata.length) {
      metadata.forEach((file, index) => {
        formBody.append(`metadata_docs`, file)
      })
      console.log('metadocs', metadata)
    }
  
    //append the unstructured files individually
    if (unstructured && unstructured.length) {
      unstructured.forEach((file, index) => {
        formBody.append(`unstructured_tech_docs`, file)
      })
      console.log('unstructured', unstructured)
    }
  
    // Append the case study files individually
    if (studies && studies.length) {
      studies.forEach((file, index) => {
        formBody.append(`case_studies`, file)
      })
      console.log('studies', studies)
    }
    setLoading(true)
    makeAuthenticatedRequest(`${process.env.REACT_APP_API_URL}/manage/experts/create`,{
      method: "POST",
      body: formBody
    }).then(resp => resp.json().then(data => ({ status: resp.ok, json:data })))
    .then(({status, json}) => {
      console.log(json)
      setRespObject({success: status, message: !status ? `Something went wrong with your uploading: ${JSON.stringify(json.detail)}` : 
        "Your information has been sent!  Please give us a few days to verify your expertise and get back to you."})
      setLoading(false)
      setExpertSubmitted(true)
    })
    .catch(e => {
      console.error(e)
      setRespObject({success: false, message:`an unknown error occured ${String(e)}`})
      setLoading(false)
    })
  }

  const generateStep = (stepIndex) => {
    return(
      createElement(STEPS[stepIndex].Component, {
        ...STEPS[stepIndex].additionalProps,
        agent: agentInfo,
        onError: STEPS[stepIndex].errorFunc || handleError,
        updateAgent: updateAgentInfo,
        sessionId: sessionId,
        updateComplete: (status) => updateStepCompletion(stepIndex, status)
        
      })
    )
  }

  //stepper version
  return (
    <BackgroundTemplate>
      <ErrorSnackBar
      open={Boolean(errorString)}
      onClose={() => setErrorString("")}
      message={
        <Typography sx={{whiteSpace: 'pre-wrap', fontSize:fontSizes.default-5}}>
          {errorString}
        </Typography>
      }
      anchorOrigin={{vertical: "bottom", horizontal:"left"}}
      autoHideDuration={5000}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexGrow:1}}>
        {
          //render different stepper based on screen size
          isMdScreen ? 
          <Box sx={{ 
          width: { xs: '100%', md: '80rem' }, 
          maxWidth: '100%', 
          padding: '1%', 
          paddingBottom: '0%',
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent:'space-between'
          }}>
            <Stepper activeStep={currentStep} sx={{ maxWidth: '100%' }}>
              {STEPS.map((step, index) => (
                <Step key={step.name} completed={stepsCompleted[index]}>
                  <StepButton color="inherit" onClick={() => handleStep(index)}>
                    <Typography sx={{ 
                      fontSize: fontSizes.default-2, 
                      color: index === currentStep ? theme.palette.primary.main : undefined, 
                      fontFamily: Anta.fontFamily 
                    }}>
                      {step.name}
                    </Typography>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            
            <Box
              id="content"
              sx={{
                marginTop: '1%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,  // This makes the content grow to fill remaining space
                justifyContent:'space-between'
              }}
            >
              {generateStep(currentStep)}

              {/* Buttons positioned at the bottom */}
              <Box sx={{ width: '100%' }}>
                <Grid container sx={{ width: '100%', flexDirection: 'row', display: 'flex', marginTop: '1%' }}>
                  <Grid item container xs={6} sx={{ display: 'flex', padding: '5px' }}>
                    <CustomButton disabled={currentStep === 0} onClick={handleBack}>
                      Back
                    </CustomButton>
                  </Grid>
                  <Grid item container xs={6} sx={{ display: 'flex', padding: '5px' }}>
                    {currentStep !== STEPS.length - 1 ? (
                      <CustomButton disabled={currentStep === STEPS.length - 1 || !stepsCompleted[currentStep]} onClick={handleNext}>
                        Next
                      </CustomButton>
                    ) : !expertSubmitted ? (
                      <CustomButton onClick={handleSubmit} disabled={!readyToSubmit()}>
                        Submit
                      </CustomButton>
                    ) : 
                    (
                      <CustomButton onClick={resetForm}>
                        Create New
                      </CustomButton>
                    )
                    }
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        : 
        <Box 
          sx={{ 
            width: "100%", 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between'  // Align items at the top and bottom
          }}
        >
          <Box sx={{ flex: 1, display:'flex', flexDirection:'column' }} id="test">

              <Typography 
                align="center" 
                sx={{ 
                  fontSize: fontSizes.titleSmall - 10, 
                  color: theme.palette.primary.main, 
                  fontFamily: Anta.fontFamily 
                }}
              >
                {STEPS[currentStep].name}
              </Typography>
            <Box
              id="content"
              sx={{
                marginTop: '1%',
                flexDirection: 'column',
                display:'flex',
                flex: 1,  // This makes the content grow to fill remaining space
                padding:'10px',
                justifyContent:'space-between'
              }}
            >
              {generateStep(currentStep)}
            </Box>
          </Box>
          
          <MobileStepper
            variant="progress"
            steps={STEPS.length}
            position="static"
            activeStep={currentStep}
            nextButton={
              <>
              {
                currentStep !== STEPS.length - 1 ?
                <Button
                size="small"
                onClick={handleNext}
                disabled={currentStep === STEPS.length - 1 || !stepsCompleted[currentStep]}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
                : !expertSubmitted ?
                <Button
                size="small"
                onClick={handleSubmit}
                disabled={!readyToSubmit()}
                >
                  Submit
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
                :
                <Button
                size="small"
                onClick={resetForm}
                >
                  Create New 
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              
              }
              </>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={currentStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
        
        }
        
      </Box>
      <ResponseDialog loading={loading} responseObj={respObject} onClose={(e) => {e.preventDefault(); setLoading(false); setRespObject(null)}}/>
    </BackgroundTemplate>
  );
}