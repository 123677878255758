import { useState } from "react";
import { makeAuthenticatedRequest } from "../../util/Firebase";
import CustomButton from "../general/CustomButton";
import { themeOptions } from "../../Constants/Colors";
import { fontSizes } from "../../Constants/Fonts";

export default function DownloadChatButton({data, text="Download Chat"}){

    const [downloading, setDownloading] = useState(false)

    const downloadChat = () => {
        setDownloading(true)
        makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/document/download_convo`,
            {
                method: "POST",
                headers:{"content-type": "application/json"},
                body:  JSON.stringify({input:data})
            }
        ).then(async (resp)=>{
            if(!resp.ok)
               console.error('something went wrong when downloading')
            const blob = await resp.blob();

            // Create a temporary link element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'generated_file.pdf'; // Set the file name
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

            setDownloading(false)
        }).catch(err => console.error(err))
    }

    const buttonStyle = {
        fullWidth: true,
        variant: 'contained',
        fontSize: fontSizes.default,
        sx:{
            textTransform:"none",
            height:'100%',
            borderRadius:2,
            color: themeOptions.palette.text.contrast,
            '&:hover':{
                color:themeOptions.palette.secondary.main
            }
        }
    }
    

    return(
        <CustomButton buttonProps={buttonStyle} disabled={data && (downloading || (data.length > 1 && !data.data && data.role === 'assistant'))}
        onClick={(e) => {e.preventDefault(); downloadChat()}}>
            {text}
        </CustomButton>
    )
}